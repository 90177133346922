'use client';

import { type ReactNode } from 'react';

import { Button, ButtonVariant } from '../../../button';
import { Close } from '../../../svgs';

import styles from './web-prompt.module.scss';

type WebPromptProps = {
  bodyLabel: string;
  closeLabel: string;
  installBtnLabel: string;
  logo: ReactNode;
  onAccept: () => void;
  onClose: () => void;
};

export const WebPrompt = ({
  bodyLabel,
  closeLabel,
  installBtnLabel,
  logo,
  onAccept,
  onClose,
}: WebPromptProps) => {
  return (
    <div className={styles['prompt-wrapper']}>
      <div className={styles.prompt}>
        {logo}
        <p className={styles['prompt__message']}>{bodyLabel}</p>
        <Button className={styles['prompt__install-button']} onClick={onAccept}>
          {installBtnLabel}
        </Button>
        <Button
          aria-label={closeLabel}
          className={styles['prompt__close-button']}
          onClick={onClose}
          variant={ButtonVariant.Secondary}
        >
          <Close />
        </Button>
      </div>
    </div>
  );
};
