'use client';

import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { Loader } from '@module/mdrt-org/shared/components';

type ImpersonationLoginContentProps = {
  cookieImpersonatedPersonId: string | undefined;
};

export const ImpersonationLoginContent = ({
  cookieImpersonatedPersonId,
}: ImpersonationLoginContentProps) => {
  const { data, status, update: updateSession } = useSession();

  const shouldUpdateSession =
    status === 'authenticated' &&
    cookieImpersonatedPersonId !== undefined &&
    cookieImpersonatedPersonId !== '' &&
    cookieImpersonatedPersonId !== data?.personId.toString();

  useEffect(() => {
    const update = async () => {
      if (shouldUpdateSession) {
        await updateSession({
          personId: cookieImpersonatedPersonId,
          personIdBeforeImpersonation:
            cookieImpersonatedPersonId === data?.personIdBeforeImpersonation?.toString()
              ? undefined
              : data?.personIdBeforeImpersonation ?? cookieImpersonatedPersonId,
        });
        location.reload();
      }
    };

    update();
  }, [cookieImpersonatedPersonId, data, shouldUpdateSession, updateSession]);

  return <Loader />;
};
