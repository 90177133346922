import { ExternalLinkIcon, MdrtLogoSlogan, RenderHTML } from '@shared/ui-components';

import { Link } from '../../i18n/i18n-navigation-link';
import { ContentContainer } from '../content-container';

import { AppStoreBadge } from './components/app-store-badge';
import { GooglePlayBadge } from './components/google-play-badge';
import { type SocialIcon } from './enums/social-icon';
import { IconMap } from './utils';
import styles from './footer.module.scss';

type LinkBlock = {
  displayText: string;
  iconClass?: string;
  linkToContent: string;
  target: string;
};

type FooterProps = {
  address: string;
  appStoreAltText: string;
  appStoreLink: string;
  chapterSitesHeader: string;
  chapterSitesLinks: LinkBlock[] | undefined;
  contactUsLink: LinkBlock;
  copyright: string;
  disclaimerLink: LinkBlock;
  footerLogoAltText: string;
  footerLogoLink: LinkBlock;
  googlePlayAltText: string;
  googlePlayLink: string;
  helpfulLinks: LinkBlock[] | undefined;
  helpfulLinksHeader: string;
  mdrtAppsLabel: string;
  phoneNumberLink: LinkBlock;
  privacyLink: LinkBlock;
  socialLinks: LinkBlock[] | undefined;
};

export const Footer = ({ cmsContent, locale }: { cmsContent: FooterProps; locale: string }) => {
  const footerHeadingClass = styles.footer__heading;
  const footerLinksGridClass = styles['footer__links-grid'];

  const isExternalLink = '_blank';

  return (
    <ContentContainer>
      <footer className={styles.footer} data-testid="footer" id="footer">
        <div className={styles.footer__container}>
          <div className={styles.footer__content}>
            <div className={styles.footer__contacts}>
              <Link
                aria-label={cmsContent.footerLogoLink?.displayText}
                data-testid="footer-mdrt-logo-link"
                href={cmsContent.footerLogoLink?.linkToContent}
              >
                <MdrtLogoSlogan className={styles.footer__logo} />
              </Link>
              <Link
                className={styles['footer__contacts-link']}
                data-ga-phone-call
                data-testid="footer-phone-number-link"
                href={cmsContent.phoneNumberLink.linkToContent}
                target={cmsContent.phoneNumberLink.target}
              >
                {cmsContent.phoneNumberLink.displayText}
              </Link>
              <RenderHTML
                as="p"
                className={styles.footer__address}
                data-testid="footer-address"
                html={cmsContent.address}
              />
              <Link
                className={styles['footer__contacts-link']}
                data-testid="footer-contacts-link"
                href={cmsContent.contactUsLink?.linkToContent}
              >
                {cmsContent.contactUsLink?.displayText}
              </Link>
            </div>
            <div
              className={styles['footer__helpful-links']}
              data-testid="footer-helpful-links-container"
            >
              <p className={footerHeadingClass}>{cmsContent.helpfulLinksHeader}</p>
              <ul className={footerLinksGridClass}>
                {cmsContent.helpfulLinks?.map((helpfulLink: LinkBlock) => {
                  return (
                    <li key={helpfulLink.linkToContent}>
                      <Link
                        className={styles['footer__links-grid-item']}
                        href={helpfulLink.linkToContent || '#'}
                        target={helpfulLink.target}
                      >
                        {helpfulLink.target === isExternalLink ? (
                          <span>
                            {helpfulLink.displayText}
                            <ExternalLinkIcon className={styles['footer__links-grid-item-icon']} />
                          </span>
                        ) : (
                          helpfulLink.displayText
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={styles['footer__chapter-sites']}
              data-testid="footer-chapter-sites-container"
            >
              <p className={footerHeadingClass}>{cmsContent.chapterSitesHeader}</p>
              <ul className={footerLinksGridClass}>
                {cmsContent.chapterSitesLinks?.map((chapterLink: LinkBlock) => {
                  return (
                    <li key={chapterLink.linkToContent}>
                      <Link
                        className={styles['footer__links-grid-item']}
                        href={chapterLink.linkToContent || '#'}
                        target={chapterLink.target}
                      >
                        {chapterLink.target === isExternalLink ? (
                          <span>
                            {chapterLink.displayText}
                            <ExternalLinkIcon className={styles['footer__links-grid-item-icon']} />
                          </span>
                        ) : (
                          chapterLink.displayText
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles['footer__mdrt-apps']} data-testid="footer-apps-container">
              <p className={footerHeadingClass}>{cmsContent.mdrtAppsLabel}</p>
              <div className={styles.footer__badges}>
                <GooglePlayBadge
                  badgeAltText={cmsContent.googlePlayAltText}
                  className={styles['footer__badge-google-play']}
                  data-testid="footer-google-play-badge"
                  locale={locale}
                  url={cmsContent.googlePlayLink}
                />
                <AppStoreBadge
                  badgeAltText={cmsContent.appStoreAltText}
                  className={styles['footer__badge-app-store']}
                  data-testid="footer-app-store-badge"
                  locale={locale}
                  url={cmsContent.appStoreLink}
                />
              </div>
            </div>
          </div>
          <div className={styles['footer__bottom-container']}>
            <div className={styles['footer__copyright']}>
              <RenderHTML
                as="p"
                className={styles['footer__copyright-heading']}
                html={cmsContent.copyright}
              />
              <div className={styles['footer__copyright-links']}>
                <Link
                  className={styles['footer__copyright-links-item']}
                  data-testid="footer-disclaimer-link"
                  href={cmsContent.disclaimerLink.linkToContent}
                  target={cmsContent.disclaimerLink.target}
                >
                  {cmsContent.disclaimerLink.displayText}
                </Link>
                <Link
                  className={styles['footer__copyright-links-item']}
                  data-testid="footer-copyright-link"
                  href={cmsContent.privacyLink.linkToContent}
                  target={cmsContent.privacyLink.target}
                >
                  {cmsContent.privacyLink.displayText}
                </Link>
              </div>
            </div>
            <ul
              className={styles['social-links-grid']}
              data-testid="footer-social-links-list-container"
            >
              {cmsContent.socialLinks?.map((item: LinkBlock) => {
                const IconComponent = IconMap[item.iconClass as SocialIcon];

                return IconComponent ? (
                  <li key={item.displayText}>
                    <Link
                      aria-label={item.displayText}
                      data-ga-social-icon
                      data-testid="footer-social-link"
                      href={item.linkToContent}
                      rel="noopener noreferrer"
                      target={item.target}
                    >
                      <IconComponent />
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        </div>
      </footer>
    </ContentContainer>
  );
};
