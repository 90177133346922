'use client';

import classNames from 'classnames';

import { Chip, ChipTheme, GlobeNav, GlobeNavSecondary, ModalTrigger } from '@shared/ui-components';
import { mapOptimizelyLocaleToIntlLocale } from '@shared/utils';

import { Dialog } from '../dialog/dialog';

import styles from './language-switcher.module.scss';

export type LanguagesList = Array<{
  languageId: string | null | undefined;
  languageName: string | null | undefined;
}>;

type LanguageSwitcherProps = {
  currentLocale: string | undefined;
  languagesList: LanguagesList;
  onLanguageChange: (locale: string) => void;
  selectLanguageLabel: string;
};

export const LanguageSwitcher = ({
  currentLocale,
  languagesList,
  onLanguageChange,
  selectLanguageLabel,
}: LanguageSwitcherProps) => {
  return (
    <ModalTrigger
      renderTrigger={({ handleModalOpen, ...restTriggerProps }) => (
        <button
          {...restTriggerProps}
          aria-label={selectLanguageLabel}
          className={styles['language-switcher__toggle-button']}
          data-testid="language-switcher-toggle-button"
          onClick={handleModalOpen}
        >
          <GlobeNav className={styles['language-switcher__toggle-button-icon']} />
          <GlobeNavSecondary className={styles['language-switcher__toggle-button-icon--mobile']} />
          <div className={styles['language-switcher__toggle-button-text']}>
            {selectLanguageLabel}
          </div>
        </button>
      )}
    >
      {(handleModalClose) => (
        <Dialog onModalClose={handleModalClose} title={selectLanguageLabel}>
          <ul className={styles['language-switcher__actions-list']}>
            {languagesList &&
              languagesList.map((language) => (
                <Chip
                  className={classNames(styles['language-switcher__chip'], 'language-button')}
                  data-ga-exclude-tracking
                  data-language-button-code={language.languageId}
                  data-language-switcher={language.languageId ?? ''}
                  key={language.languageId}
                  label={language.languageName ?? ''}
                  lang={
                    language.languageId
                      ? mapOptimizelyLocaleToIntlLocale(language.languageId)
                      : undefined
                  }
                  onClick={() => onLanguageChange(language.languageId ?? '')}
                  theme={
                    currentLocale === language.languageId?.toLocaleLowerCase()
                      ? ChipTheme.Active
                      : ChipTheme.Light
                  }
                />
              ))}
          </ul>
        </Dialog>
      )}
    </ModalTrigger>
  );
};
