'use client';

import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { type SelectOption } from '@shared/ui-components';

import { Loader, SelectField } from '../../../../components';
import { getMembershipRequirementsPerCountry } from '../../../../utils/data';
import { OrgSharedDataTag } from '../../../../utils/data/constants/tags';
import {
  type SiteSettingsPage,
  type MembershipRequirementBlock,
} from '../../../../utils/data/graphql/_generated/gql-generated';
import {
  type MembershipRequirementsPerYear,
  type MembershipRequirementDimensions,
} from '../../../../utils/types';
import {
  getDefaultCountryOption,
  type MembershipRequirementsBlockWithFallbacks,
} from '../../utils/helpers';
import { useMembershipRequirementsOptions } from '../../utils/hooks';
import { CertifyingLetters } from '../certifying-letters';
import { EthicalCode } from '../ethical-code/ethical-code';
import { MembershipDues } from '../membership-dues/membership-dues';
import { ProductionRequirements } from '../production-requirements/production-requirements';

import styles from './membership-requirements.module.scss';

type MembershipRequirementsProps = {
  blockData: MembershipRequirementsBlockWithFallbacks;
  dimensions: MembershipRequirementDimensions;
  siteSettingsData?: SiteSettingsPage;
  userCountry: { countryCode: string; countryName: string };
};

export const MembershipRequirements = ({
  blockData,
  dimensions,
  siteSettingsData,
  userCountry,
}: MembershipRequirementsProps) => {
  const [dropdownsReady, setDropdownsReady] = useState<boolean>(false);
  const { countryOptions, yearOptions } = useMembershipRequirementsOptions(dimensions);
  const [selectedCountry, setSelectedCountry] = useState<
    (SelectOption<string> & { countryName: string }) | null
  >(getDefaultCountryOption({ countryOptions, userCountry }));
  const [selectedYear, setSelectedYear] = useState<SelectOption<number> | null>(
    yearOptions?.[0] ?? null
  );

  const {
    data: countryYearData,
    error,
    isFetching,
  } = useQuery({
    enabled: Boolean(selectedCountry?.value) && Boolean(selectedYear?.value),
    queryFn: () =>
      getMembershipRequirementsPerCountry({
        countryCode: selectedCountry?.value ?? '',
        countryName: selectedCountry?.countryName ?? '',
      }),
    queryKey: [
      OrgSharedDataTag.GET_MEMBERSHIP_REQUIREMENTS,
      `${OrgSharedDataTag.GET_MEMBERSHIP_REQUIREMENTS}_${selectedCountry?.value}_${selectedCountry?.label}`,
    ],
    select: (allYears) =>
      allYears?.requirements.find(
        (yearData: MembershipRequirementsPerYear) => yearData.year === selectedYear?.value
      ),
  });

  useEffect(() => {
    // workaround for - react-select causes differences between server-side and client-side rendered html
    setDropdownsReady(true);
  }, []);

  const selectedYearMembershipRequirementBlockData = blockData.MembershipRequirementBlocks?.find(
    (membershipRequirementBlock) =>
      new Date(
        (
          membershipRequirementBlock?.ContentLink?.Expanded as
            | MembershipRequirementBlock
            | undefined
        )?.Date
      ).getFullYear() === countryYearData?.year
  )?.ContentLink?.Expanded as MembershipRequirementBlock | undefined;

  return (
    <div className={styles['requirements-wrapper']}>
      <section
        {...(blockData?.ProductionRequirementsAnchorLink && {
          id: blockData.ProductionRequirementsAnchorLink,
        })}
      >
        {isFetching && <Loader allowScroll isInner />}
        <div className={styles.header}>
          <h2>{blockData?.ProductionRequirementsStepLabel}</h2>
          <div className={styles['header__country-selector']}>
            {dropdownsReady ? (
              <SelectField
                id="country-selector"
                isRequired
                label={siteSettingsData?.CountryLabel ?? 'Country'}
                name="country"
                onChange={(value) =>
                  setSelectedCountry(value as SelectOption<string> & { countryName: string })
                }
                options={countryOptions}
                value={selectedCountry}
                withoutController
              />
            ) : (
              <Skeleton className={styles['header__dropdown-skeleton']} />
            )}
          </div>
          <div className={styles['header__year-selector']}>
            {dropdownsReady ? (
              <SelectField
                id="year-selector"
                isRequired
                label={siteSettingsData?.YearLabel ?? 'Year'}
                name="year"
                onChange={(value) => setSelectedYear(value as SelectOption<number>)}
                options={yearOptions}
                value={selectedYear}
                withoutController
              />
            ) : (
              <Skeleton className={styles['header__dropdown-skeleton']} />
            )}
          </div>
        </div>
        <ProductionRequirements
          blockData={blockData}
          countryYearData={countryYearData}
          error={error}
          isFetching={isFetching}
          yearBlockData={selectedYearMembershipRequirementBlockData}
        />
      </section>
      <CertifyingLetters
        blockData={blockData}
        countryYearData={countryYearData}
        isFetching={isFetching}
        yearBlockData={selectedYearMembershipRequirementBlockData}
      />
      <EthicalCode blockData={blockData} />
      <MembershipDues
        blockData={blockData}
        countryYearData={countryYearData}
        isFetching={isFetching}
        yearBlockData={selectedYearMembershipRequirementBlockData}
      />
    </div>
  );
};
