import { withSpinnerLoaderComponent } from '@shared/ui-components';
import { LoaderProps } from '@shared/ui-components';
import { Loader as LoaderBase } from '@shared/ui-components';
import { LoaderSpinner } from '@module/mdrt-org/shared/components/loader/loader-spinner';

export const Loader = (props: Omit<LoaderProps, 'loaderSpinner'>) =>
  withSpinnerLoaderComponent(LoaderBase, {
    ...props,
    loaderSpinner: <LoaderSpinner />,
  });
