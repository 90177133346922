'use client';

import { useEffect, useState } from 'react';

import { BookmarkFilledIcon, BookmarkIcon } from '@shared/ui-components';
import { formatTranslationStringOnClient } from '@shared/utils';

import { deleteBookmarks, postBookmarks } from '../../utils/data';
import { useAddDefaultErrorToast, useMutationHandler } from '../../utils/hooks';
import { Button } from '../button';
import { useUserContext } from '../providers';

import { type BookmarkButtonCmsContent } from './get-bookmark-button-cms-content';
import { getContentCategory } from './get-content-category-label';

type BookmarkButtonClientProps = {
  cmsContent: BookmarkButtonCmsContent;
  contentType: number | null | undefined;
  initialIsBookmarked: boolean;
  learningMaterialId: string | null | undefined;
};

export const BookmarkButtonClient = ({
  cmsContent,
  contentType,
  initialIsBookmarked,
  learningMaterialId,
}: BookmarkButtonClientProps) => {
  const { status } = useUserContext();
  const [isBookmarked, setIsBookmarked] = useState(initialIsBookmarked);
  const addErrorToast = useAddDefaultErrorToast();
  const isAuthenticated = status === 'authenticated';

  useEffect(() => {
    setIsBookmarked(initialIsBookmarked);
  }, [initialIsBookmarked]);

  const contentTypeLabel = getContentCategory({
    contentType,
    labels: cmsContent.contentCategoryLabels,
  });
  const { isMutationPending: isAddBookmarkPending, mutate: addBookmark } = useMutationHandler({
    mutationFunction: postBookmarks,
    onFailure: () => {
      addErrorToast();
      setIsBookmarked(false);
    },
    scrollToTop: false,
    successMessage: formatTranslationStringOnClient(cmsContent.addBookmarkSuccessMessage, {
      contentType: contentTypeLabel,
    }),
  });
  const { isMutationPending: isDeletePending, mutate: deleteBookmark } = useMutationHandler({
    mutationFunction: deleteBookmarks,
    onFailure: () => {
      addErrorToast();
      setIsBookmarked(false);
    },
    scrollToTop: false,
    successMessage: formatTranslationStringOnClient(cmsContent.removeBookmarkSuccessMessage, {
      contentType: contentTypeLabel,
    }),
  });

  const handleBookmarkToggle = () => {
    setIsBookmarked(!isBookmarked);

    if (isBookmarked) {
      deleteBookmark({ bookmarkId: learningMaterialId });
    } else {
      addBookmark({ learningMaterialId });
    }
  };

  if (!isAuthenticated) return null;

  return (
    <Button
      Icon={isBookmarked ? BookmarkFilledIcon : BookmarkIcon}
      aria-label={
        isBookmarked ? cmsContent.removeBookmarkAriaLabel : cmsContent.addBookmarkAriaLabel
      }
      aria-pressed={isBookmarked}
      disabled={isAddBookmarkPending || isDeletePending}
      onClick={handleBookmarkToggle}
    />
  );
};
