import { auth } from '@shared/misc';

import { fetchData, getApiHeaders, withErrorHandling } from '../helpers';
import { type Bookmark } from '../types/bookmark';

import { OrgSharedDataTag } from './constants/tags';

export const getBookmarks = async (): Promise<Bookmark[]> => {
  const session = await auth();
  const personId = session?.personId;

  if (!personId) return [];

  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}/bookmarks`,
      cache: 'no-store',
      headers: getApiHeaders(session),
      next: {
        tags: [OrgSharedDataTag.GET_BOOKMARKS, `${OrgSharedDataTag.GET_BOOKMARKS}_${personId}`],
      },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}/bookmarks'
  );
};
