import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVerifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <mask
      id="verified-icon_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#verified-icon_svg__a)">
      <path
        fill="currentColor"
        d="m10.95 13.066-1.623-1.608a.72.72 0 0 0-.514-.213.72.72 0 0 0-.54.228.72.72 0 0 0-.217.527q0 .31.217.527l2.045 2.044q.27.27.632.271.361 0 .633-.27l4.144-4.145a.7.7 0 0 0 .22-.522.77.77 0 0 0-.22-.532.77.77 0 0 0-.534-.24.7.7 0 0 0-.535.225zm-2.54 8.117-1.383-2.325-2.615-.562a.83.83 0 0 1-.538-.354.88.88 0 0 1-.16-.625l.255-2.69-1.778-2.035A.84.84 0 0 1 1.96 12q0-.346.23-.592l1.78-2.035-.256-2.69a.88.88 0 0 1 .16-.625.83.83 0 0 1 .538-.354l2.615-.562L8.41 2.817a.92.92 0 0 1 .492-.404.84.84 0 0 1 .633.033L12 3.488l2.466-1.042a.84.84 0 0 1 .632-.033.92.92 0 0 1 .492.404l1.383 2.325 2.616.562a.83.83 0 0 1 .537.354q.201.288.16.625l-.255 2.69 1.779 2.035q.23.246.23.592t-.23.592l-1.779 2.035.256 2.69a.88.88 0 0 1-.16.625.83.83 0 0 1-.538.354l-2.616.562-1.382 2.325a.92.92 0 0 1-.493.404.84.84 0 0 1-.633-.033L12 20.512l-2.465 1.042a.84.84 0 0 1-.633.033.92.92 0 0 1-.492-.404"
      />
    </g>
  </svg>
);
export default SvgVerifiedIcon;
