'use client';

import classNames from 'classnames';

import { ChipTheme, MdrtFullLogo } from '@shared/ui-components';

import { Link } from '../../../../i18n';
import { HOMEPAGE_PATH } from '../../../../utils/constants';
import { type LanguagesContent } from '../../../../utils/hooks/get-languages-content';
import { useLanguageSwitcher } from '../../../../utils/hooks/use-language-switcher';
import { type ProfileSummary } from '../../../../utils/types';
import { type NavigationItem } from '../../../../utils/types/navigation-items';
import { AuthenticationButton } from '../../../authentication-button';
import { LanguageSwitcher } from '../../../language-switcher/language-switcher';
import { useGlobalCmsContentContext } from '../../../providers';
import { type HeaderAccountDropdownContent } from '../../graphql';
import { Navigation } from '../navigation/navigation';
import { ProfileDropdown } from '../profile-dropdown/profile-dropdown';
import { StatusChip } from '../status-chip/status-chip';

import styles from './header-content.module.scss';

type HeaderContentProps = {
  isAuthenticated: boolean;
  isSecondaryTheme: boolean;
  languagesCmsContent: LanguagesContent;
  localeURLSegment?: string;
  navigationItems: NavigationItem[];
  profileDropdownCmsContent: HeaderAccountDropdownContent;
  profileSummary: ProfileSummary;
  statusChipLabel: string | undefined;
  statusChipUrl: string;
};

export const HeaderContent = ({
  isAuthenticated,
  isSecondaryTheme,
  languagesCmsContent,
  localeURLSegment,
  navigationItems,
  profileDropdownCmsContent,
  profileSummary,
  statusChipLabel,
  statusChipUrl,
}: HeaderContentProps) => {
  const { cmsContent: globalCmsContent } = useGlobalCmsContentContext();
  const { onLanguageChange } = useLanguageSwitcher();
  const locale = localeURLSegment ?? 'en';

  return (
    <div
      className={classNames(styles['header-content'], {
        [styles['header-content--secondary']]: isSecondaryTheme,
      })}
    >
      <Link
        aria-label={globalCmsContent.goBackToHomePageLabel}
        data-testid="mdrt-logo-button"
        href={HOMEPAGE_PATH}
      >
        <MdrtFullLogo className={styles['header-content__logo']} />
      </Link>
      <Navigation isSecondaryTheme={isSecondaryTheme} navigationItems={navigationItems} />
      <div className={styles['header-content__controls']}>
        {isAuthenticated && (
          <StatusChip
            label={statusChipLabel}
            theme={isSecondaryTheme ? undefined : ChipTheme.Light}
            url={statusChipUrl}
          />
        )}
        <LanguageSwitcher
          currentLocale={localeURLSegment}
          languagesList={languagesCmsContent.languagesList}
          onLanguageChange={onLanguageChange}
          selectLanguageLabel={languagesCmsContent.selectLanguage}
        />
        {isAuthenticated ? (
          <ProfileDropdown
            cmsContent={profileDropdownCmsContent}
            isAuthenticated={isAuthenticated}
            locale={locale}
            profileSummary={profileSummary}
          />
        ) : (
          <AuthenticationButton isAuthenticated={isAuthenticated} locale={locale} />
        )}
      </div>
    </div>
  );
};
