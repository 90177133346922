'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { type PropsWithChildren } from 'react';
import { RouterProvider } from 'react-aria-components';

import { GlobalToastContextProvider } from '@shared/ui-components';

import { useRouter } from '../../i18n/i18n-navigation';

import { GlobalLoaderContextProvider } from './global-loader-context';
import { GTMClickTracker } from './gtm-click-tracker';
import { SubNavigationContextProvider } from './sub-navigation-context';

const makeQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        throwOnError: true,
      },
    },
  });

let browserQueryClient: QueryClient | undefined;

const getQueryClient = () => {
  if (typeof window === 'undefined') {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
};

export const Providers = ({ children }: PropsWithChildren) => {
  const queryClient = getQueryClient();
  const router = useRouter();

  return (
    <GlobalToastContextProvider>
      <GlobalLoaderContextProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <RouterProvider navigate={router.push}>
            <SubNavigationContextProvider>
              <GTMClickTracker>{children}</GTMClickTracker>
            </SubNavigationContextProvider>
          </RouterProvider>
        </QueryClientProvider>
      </GlobalLoaderContextProvider>
    </GlobalToastContextProvider>
  );
};
