import { fetchData, getApiHeaders, withErrorHandling } from '../helpers';
import { type Designation } from '../types/designation';

import { OrgSharedDataTag } from './constants/tags';

export const getDesignations = async (): Promise<Designation[]> => {
  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_PROFILE_ROUTE}/Designations`,
      headers: getApiHeaders(),
      next: {
        tags: [OrgSharedDataTag.GET_DESIGNATIONS],
      },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_PROFILE_ROUTE}/Designations'
  );
};
