'use client';

import { type KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Popover, Dialog } from 'react-aria-components';

import { Card, ProfileIcon } from '@shared/ui-components';
import { KeyboardKey, useClickOutside } from '@shared/utils';

import { type ProfileSummary } from '../../../../utils/types';
import { type HeaderAccountDropdownContent } from '../../graphql';
import { useImpersonationModal } from '../impersonation-modal/impersonation-modal';
import { ProfileDropdownItems } from '../profile-dropdown-items/profile-dropdown-items';
import { ProfileDropdownSummary } from '../profile-dropdown-summary/profile-dropdown-summary';

import styles from './profile-dropdown.module.scss';

const PARENT_CLASSNAME = 'profile-dropdown';

export const ProfileDropdown = ({
  cmsContent,
  isAuthenticated,
  locale,
  profileSummary,
}: {
  cmsContent: HeaderAccountDropdownContent;
  isAuthenticated: boolean;
  locale: string;
  profileSummary: ProfileSummary;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const popOverRef = useRef<HTMLDivElement>(null);
  const { openImpersonationModal, renderImpersonationModal } = useImpersonationModal({
    cmsContent,
  });

  const wrapperRef = useClickOutside((event) => {
    if (popOverRef.current && popOverRef.current?.contains(event.target as Node)) {
      return;
    }

    setIsOpen(false);
  });

  const handleWrapperKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyboardKey.Escape) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => setIsOpen(false);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {renderImpersonationModal()}
      <div className={styles[PARENT_CLASSNAME]} onKeyUp={handleWrapperKeyUp} ref={wrapperRef}>
        <button
          aria-label={cmsContent.profileDropdownTriggerAriaLabel}
          className={styles[`${PARENT_CLASSNAME}__trigger`]}
          onClick={() => setIsOpen(!isOpen)}
          ref={triggerRef}
          type="button"
        >
          <ProfileIcon />
        </button>
        <Popover
          className={styles[`${PARENT_CLASSNAME}__popover`]}
          isNonModal
          isOpen={isOpen}
          offset={8}
          placement="bottom end"
          triggerRef={triggerRef}
        >
          <Dialog aria-label={cmsContent.profileDropdownAriaLabel}>
            <Card
              className={styles[`${PARENT_CLASSNAME}__content`]}
              hasSmallRadius
              ref={popOverRef}
            >
              <ProfileDropdownSummary cmsContent={cmsContent} profileSummary={profileSummary} />
              <ul>
                <ProfileDropdownItems
                  cmsContent={cmsContent}
                  isAuthenticated={isAuthenticated}
                  locale={locale}
                  onClose={() => setIsOpen(false)}
                  openImpersonationModal={openImpersonationModal}
                />
              </ul>
            </Card>
          </Dialog>
        </Popover>
      </div>
    </>
  );
};
