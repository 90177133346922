import { auth } from '@shared/misc';

import { fetchData } from '../helpers/fetch-data';
import { getApiHeaders } from '../helpers/get-headers';
import { withErrorHandling } from '../helpers/with-error-handling';
import { type MembershipApplicationDto } from '../types';

import { OrgSharedDataTag } from './constants/tags';

export const getMembershipApplication = async ({
  personId,
  year,
}: {
  personId?: number;
  year?: number;
}): Promise<MembershipApplicationDto | null> => {
  const session = await auth();

  const response = await fetchData({
    apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/MembershipApplication/${personId}/Year/${year}`,
    headers: getApiHeaders(session),
    ...(process.env.DISABLE_PERSON_SCOPED_DATA_FETCH_CACHE === 'true' && { cache: 'no-store' }),
    next: {
      tags: [
        OrgSharedDataTag.GET_MEMBERSHIP_APPLICATION,
        `${OrgSharedDataTag.GET_MEMBERSHIP_APPLICATION}_${personId}`,
        `${OrgSharedDataTag.GET_MEMBERSHIP_APPLICATION}_${personId}_${year}`,
      ],
    },
  });

  if (response?.status === 404) {
    return null;
  }

  return withErrorHandling(
    response,
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/MembershipApplication/${personId}/Year/${year}',
    true
  );
};
