import { type ReactElement } from 'react';

import styles from './profile-dashboard-hero-section.module.scss';

export type ProfileDashboardHeroSectionProps = {
  companyAdminSlot?: ReactElement;
  profilePhotoSlot: ReactElement;
  profileSummaryActionsSlot: ReactElement;
  profileSummarySlot: ReactElement;
};

export const ProfileDashboardHeroSection = ({
  companyAdminSlot,
  profilePhotoSlot,
  profileSummaryActionsSlot,
  profileSummarySlot,
}: ProfileDashboardHeroSectionProps) => {
  return (
    <div className={styles['hero-section']} data-testid="hero-section-content">
      {profilePhotoSlot}
      <div className={styles['hero-section__summary-container']} data-testid="profile-container">
        {profileSummarySlot}
        {profileSummaryActionsSlot}
        {companyAdminSlot}
      </div>
    </div>
  );
};
