export const MAX_FILE_SIZE = 5 * 1024 * 1024;
export const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png'];
export const PICTURE_UPLOAD_SERVER_ERROR_KEY = 'pictureUploadServerError';
export const PICTURE_UPLOAD_ERROR_ID = 'pictureUploadError';
export const ALLOWED_EXTENSIONS_SEGMENT = ALLOWED_EXTENSIONS.map((item) => item.toUpperCase()).join(
  ', '
);
export const ALLOWED_FILE_SIZE_SEGMENT = MAX_FILE_SIZE / 1024 / 1024;
export const PROFILE_PICTURE_INPUT_HINT_FALLBACK = `Only ${ALLOWED_EXTENSIONS_SEGMENT} up to ${ALLOWED_FILE_SIZE_SEGMENT} MB.`;
export const PROFILE_PICTURE_UPLOAD_SERVER_ERROR_MESSAGE_FALLBACK =
  'There is an issue with the uploaded file.';
export const FILE_TYPE_ERROR_FALLBACK = `Incorrect file format. Only ${ALLOWED_EXTENSIONS_SEGMENT} files types are accepted.`;
export const FILE_SIZE_ERROR_FALLBACK = `The image is too big or empty. Please upload images no larger than ${ALLOWED_FILE_SIZE_SEGMENT} MB.`;
