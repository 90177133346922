import { compareAlphabetically } from '@shared/utils';

import { useMappedIntlLocale } from '../../../../utils/hooks/use-mapped-intl-locale';
import { type MembershipRequirementDimensions } from '../../../../utils/types';

export const useMembershipRequirementsOptions = (dimensions: MembershipRequirementDimensions) => {
  const locale = useMappedIntlLocale();

  const formatter = new Intl.DateTimeFormat(locale, { year: 'numeric' });

  const sortedCountries = dimensions?.countries
    ?.sort((countryA, countryB) => compareAlphabetically(countryA.name, countryB.name))
    .map(({ code, name }) => {
      return {
        countryName: name,
        label: code === 'TW' ? 'Chinese Taiwan' : name,
        value: code,
      };
    });
  const taiwanCountryOptions = sortedCountries.filter((country) => country.value === 'TW');
  const countryOptionsWithoutTaiwanOption = sortedCountries.filter(
    (country) => country.value !== 'TW'
  );
  const switzerlandOptionIndex = countryOptionsWithoutTaiwanOption.findIndex(
    (country) => country.value === 'CH'
  );

  const customSortedCountryOptions = [
    ...countryOptionsWithoutTaiwanOption.slice(0, switzerlandOptionIndex + 1),
    ...taiwanCountryOptions,
    ...countryOptionsWithoutTaiwanOption.slice(switzerlandOptionIndex + 1),
  ];

  const yearOptions = dimensions?.years
    ?.sort((yearA, yearB) => yearA - yearB)
    .map((year) => ({
      label: formatter.format(new Date(year, 0)),
      value: year,
    }));

  return { countryOptions: customSortedCountryOptions, yearOptions };
};
