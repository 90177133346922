import { fetchData, getApiHeaders, withErrorHandling } from '../helpers';
import { type ApplicationDefinition } from '../types';

import { OrgSharedDataTag } from './constants/tags';

export const getApplicationDefinition = async (): Promise<ApplicationDefinition> => {
  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_MEMBERSHIP_ROUTE}/v2/ApplicationDefinition/Current`,
      headers: getApiHeaders(),
      next: { tags: [OrgSharedDataTag.GET_APPLICATION_DEFINITION] },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_MEMBERSHIP_ROUTE}/v2/ApplicationDefinition/Current',
    true
  );
};
