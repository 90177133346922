'use client';

import classnames from 'classnames';

import { Carousel } from '@module/mdrt-org/shared/components';

import { type BookmarkButtonCmsContent } from '../../../../components/bookmark-button/get-bookmark-button-cms-content';
import { type ShareDropdownCmsContent } from '../../../../components/share-dropdown/get-share-dropdown-cms-content';
import { type LearnContentCarouselCardFragment } from '../../../../utils/data/graphql/_generated/gql-generated';
import { type Bookmark } from '../../../../utils/types/bookmark';

import { CirclesGraphics, PlusesGraphics } from './assets';
import { LearnHeroCarouselCard } from './components';
import styles from './learn-hero-carousel.module.scss';

export type LearnHeroCarouselProps = {
  bookmarkButtonCmsContent: BookmarkButtonCmsContent;
  bookmarks: Bookmark[];
  cards: LearnContentCarouselCardFragment[];
  minutesLabel: string;
  shareDropdownCmsContent: ShareDropdownCmsContent;
  viewActionLabel: string;
};

export const LearnHeroCarousel = ({
  bookmarkButtonCmsContent,
  bookmarks,
  cards,
  minutesLabel,
  shareDropdownCmsContent,
  viewActionLabel,
}: LearnHeroCarouselProps) => {
  return (
    <div className={styles['carousel-wrapper']}>
      <div className={styles['carousel-inner']}>
        <PlusesGraphics
          className={classnames(styles['carousel-graphics'], styles['carousel-graphics--pluses'])}
        />
        <CirclesGraphics
          className={classnames(styles['carousel-graphics'], styles['carousel-graphics--circles'])}
        />
        <Carousel
          arrowsClassName={styles['carousel-arrows']}
          className={styles.carousel}
          deviceType="all"
          headerClassName={styles['carousel-header']}
          responsive={{
            all: {
              breakpoint: { max: Number.POSITIVE_INFINITY, min: 0 },
              items: 1,
              partialVisibilityGutter: 0,
            },
          }}
          withDefaultResponsiveConfig={false}
        >
          {cards.map(
            (card) =>
              card && (
                <div key={card?.ContentLink?.GuidValue}>
                  <LearnHeroCarouselCard
                    bookmarkButtonCmsContent={bookmarkButtonCmsContent}
                    card={card}
                    isBookmarked={bookmarks?.some(
                      (bookmark) => bookmark.bookmarkId === card.ContentLink?.GuidValue
                    )}
                    minutesLabel={minutesLabel}
                    shareDropdownCmsContent={shareDropdownCmsContent}
                    viewActionLabel={viewActionLabel}
                  />
                </div>
              )
          )}
        </Carousel>
      </div>
    </div>
  );
};
