'use client';

import classNames from 'classnames';
import { type ReactElement, useEffect } from 'react';
import { FocusScope } from 'react-aria';

import styles from './loader.module.scss';

export type LoaderProps = {
  allowScroll?: boolean;
  className?: string;
  fixedToViewportCenter?: boolean;
  isInner?: boolean;
  loaderSpinner: ReactElement;
  subtitle?: string;
  title?: string;
};

export const Loader = ({
  allowScroll,
  className,
  fixedToViewportCenter,
  isInner,
  loaderSpinner,
  subtitle,
  title,
}: LoaderProps) => {
  const withText = Boolean(subtitle) || Boolean(title);

  useEffect(() => {
    if (allowScroll) {
      return () => undefined;
    }

    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [allowScroll]);

  return (
    <FocusScope autoFocus contain restoreFocus>
      <div>
        {/* fixed position element needs to be wrapped in a simple div to not affect Next autoscroll behaviour */}
        <div
          aria-busy="true"
          aria-label="Loading, please wait"
          aria-live="polite"
          aria-valuetext="Loading, please wait"
          className={classNames(styles['loader'], className, {
            [styles['loader--with-text']]: withText,
            [styles['loader--is-inner']]: isInner,
          })}
          data-testid="loader"
          role="progressbar"
          tabIndex={0}
        >
          <div
            className={classNames(styles['loader__logo-container'], {
              [styles['loader__logo-container--centered']]: fixedToViewportCenter,
            })}
          >
            {loaderSpinner}
          </div>
          {withText && (
            <div className={styles['loader__text-container']}>
              {title && <h2>{title}</h2>}
              {subtitle && <h3>{subtitle}</h3>}
            </div>
          )}
        </div>
      </div>
    </FocusScope>
  );
};
