import { withErrorHandling, getApiHeaders, fetchData } from '../helpers';
import { type Company } from '../types/company';

import { OrgSharedDataTag } from './constants/tags';

export const getCompanies = async (): Promise<Company[]> => {
  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_PROFILE_ROUTE}/Companies`,
      headers: getApiHeaders(),
      next: {
        tags: [OrgSharedDataTag.GET_COMPANIES],
      },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_PROFILE_ROUTE}/Companies'
  );
};
