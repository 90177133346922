'use client';

import { Admin, Gear, Impersonate } from '@shared/ui-components';

import { Link } from '../../../../i18n/i18n-navigation-link';
import { AuthenticationButton } from '../../../authentication-button';
import { useUserContext } from '../../../providers/user-context';
import { type HeaderAccountDropdownContent } from '../../graphql';

import styles from './profile-dropdown-items.module.scss';

export const ProfileDropdownItems = ({
  cmsContent,
  isAuthenticated,
  locale,
  onClose,
  openImpersonationModal,
}: {
  cmsContent: HeaderAccountDropdownContent;
  isAuthenticated: boolean;
  locale: string;
  onClose?: () => void;
  openImpersonationModal: () => void;
}) => {
  const { hasCompanyAdminRole, hasStaffRole } = useUserContext();

  return (
    <>
      <li>
        {cmsContent.accountSettingsPagePath && (
          <Link
            className={styles['profile-dropdown-item']}
            href={cmsContent.accountSettingsPagePath}
            onClick={onClose}
          >
            <Gear className={styles['profile-dropdown-item__icon']} />
            {cmsContent.accountLinkLabel}
          </Link>
        )}
      </li>
      {hasStaffRole && (
        <li>
          <button
            className={styles['profile-dropdown-item-button']}
            onClick={() => {
              onClose?.();
              openImpersonationModal();
            }}
            type="button"
          >
            <Impersonate className={styles['profile-dropdown-item__icon']} />
            {cmsContent.impersonationLinkLabel}
          </button>
        </li>
      )}
      {(hasCompanyAdminRole || hasStaffRole) && cmsContent.companyAdminDasboarPagePath && (
        <li>
          <Link
            className={styles['profile-dropdown-item']}
            href={cmsContent.companyAdminDasboarPagePath}
            onClick={onClose}
          >
            <Admin className={styles['profile-dropdown-item__icon']} />
            {cmsContent.companyAdminDashboardPageLinkLabel}
          </Link>
        </li>
      )}
      {hasStaffRole && cmsContent.adminDashboardPagePath && (
        <li>
          <Link
            className={styles['profile-dropdown-item']}
            href={cmsContent.adminDashboardPagePath}
            onClick={onClose}
          >
            <Admin className={styles['profile-dropdown-item__icon']} />
            {cmsContent.adminDasboardPageLinkLabel}
          </Link>
        </li>
      )}
      <li className={styles['profile-dropdown-item__with-divider']}>
        <AuthenticationButton isAuthenticated={isAuthenticated} locale={locale} />
      </li>
    </>
  );
};
