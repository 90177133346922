'use client';

import classNames from 'classnames';
import { forwardRef, type InputHTMLAttributes } from 'react';

import styles from './checkbox-input.module.scss';

type CheckboxInputProps = {
  dataTestId?: string;
  id?: string;
  isChecked: boolean;
  isSwitch?: boolean;
  label: string;
  name?: string;
  onChange?: () => void;
  pushToRight?: boolean;
  wrapperClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      dataTestId,
      id,
      isChecked,
      isSwitch = false,
      label,
      name,
      onChange,
      pushToRight,
      wrapperClassName,
      ...restProps
    },
    ref
  ) => {
    const fieldId = id ?? name;

    const renderSwitch = () => {
      return (
        <input
          {...restProps}
          checked={Boolean(isChecked)}
          className={classNames({
            [styles['switch__control']]: isSwitch,
            [styles['checkbox__control']]: !isSwitch,
          })}
          id={fieldId}
          onChange={onChange}
          ref={ref}
          type="checkbox"
          {...(isSwitch ? { role: 'switch' } : {})}
        />
      );
    };

    return (
      <div
        className={classNames(
          {
            [styles['switch']]: isSwitch,
            [styles['checkbox']]: !isSwitch,
          },
          wrapperClassName
        )}
        data-testid={dataTestId ?? fieldId}
      >
        <label
          className={classNames({
            [styles['switch__label']]: isSwitch,
            [styles['checkbox__label']]: !isSwitch,
          })}
          htmlFor={fieldId}
        >
          {!pushToRight && renderSwitch()}
          <span>{label}</span>
          {pushToRight && renderSwitch()}
        </label>
      </div>
    );
  }
);
