// eslint-disable-next-line canonical/filename-match-regex
'use client';

import { type ComponentProps } from 'react';

import { LinkBase } from './i18n-navigation';

export const Link = (props: ComponentProps<typeof LinkBase>) => {
  const shouldDisablePrefetch =
    typeof window === 'undefined'
      ? props.prefetch
      : window.PUBLIC_ENV.disableNextPrefetchFeature === 'true';

  return <LinkBase {...props} prefetch={!shouldDisablePrefetch} />;
};
