import classNames from 'classnames';
import { type PropsWithChildren } from 'react';

import styles from './profile-summary-actions.module.scss';

export const ProfileSummaryActions = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={classNames(styles['profile-summary-actions'], className)}
      data-testid="profile-summary-action-container"
    >
      {children}
    </div>
  );
};
