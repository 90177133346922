// eslint-disable-next-line canonical/filename-match-regex
import { type LocalePrefix } from 'next-intl/dist/types/src/shared/types';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { locales } from './locales';

export const localePrefix: LocalePrefix = 'as-needed';

const NavigationHelpers = createSharedPathnamesNavigation({
  localePrefix,
  locales,
});

export const { Link: LinkBase, redirect, usePathname, useRouter } = NavigationHelpers;
