'use client';

import { Dialog } from '@module/mdrt-org/shared/components';
import { Chip } from '@module/mdrt-org/shared/components/chip';
import { useRouter } from '@module/mdrt-org/shared/i18n';
import { deleteProfilePicture } from '@module/mdrt-org/shared/utils/data/delete-profile-picture';
import { getInformationChipProps } from '@module/mdrt-org/shared/utils/data/get-chips-data';
import { type InformationChipCMSContent } from '@module/mdrt-org/shared/utils/data/get-chips-data/utils/helpers';
import { postUpgradeMembershipApplication } from '@module/mdrt-org/shared/utils/data/post-upgrade-membership-application';
import { useProfilePictureUploadMutation } from '@module/mdrt-org/shared/utils/hooks/use-profile-picture-upload-mutation';
import {
  type ApplicationDefinition,
  type CustomerProfile,
  type MembershipApplicationDto,
} from '@module/mdrt-org/shared/utils/types';
import {
  ProfileDashboardHeroSection,
  ProfilePhotoContainer,
  type ProfilePhotoContainerProps,
  ProfileSummary,
  ProfileSummaryActions,
  type ProfileSummaryProps,
} from '@shared/profile-dashboard-sections/profile-dashboard-hero-section';

import { HeroSectionCompanyAdmin } from './hero-section-company-admin';
import { HeroSectionContentWrapper } from './hero-section-content-wrapper';
import { type CompanyAdminSectionContent } from './hero-section-wrapper-server';

type HeroSectionWrapperProps = {
  companyAdminSectionCMSContent: CompanyAdminSectionContent;
  informationChipData: {
    applicationDefinition: ApplicationDefinition;
    cmsContent: InformationChipCMSContent;
    customerProfile: CustomerProfile;
    membershipApplication: MembershipApplicationDto | null;
  };
  membershipYear: number | undefined;
  personId: number | undefined;
  profilePhotoContainerPartialProps: Omit<
    ProfilePhotoContainerProps,
    | 'abortOngoingRequests'
    | 'handleSubmitProfilePicture'
    | 'handleProfilePictureDelete'
    | 'dialogComponent'
  >;
  profileSummaryProps: ProfileSummaryProps;
  showCompanyAdminSection: boolean;
};

export const HeroSectionWrapper = ({
  companyAdminSectionCMSContent,
  informationChipData,
  membershipYear,
  personId,
  profilePhotoContainerPartialProps,
  profileSummaryProps,
  showCompanyAdminSection,
}: HeroSectionWrapperProps) => {
  const router = useRouter();
  const profilePictureUploadMutation = useProfilePictureUploadMutation();

  const membershipApplicationUrl = informationChipData.cmsContent.membershipPageLink;

  const abortOngoingRequests = () => {
    profilePictureUploadMutation.cancel();
  };

  const handleSubmitProfilePicture = async (formData: BodyInit) => {
    return await profilePictureUploadMutation.mutate(formData);
  };

  const handleProfilePictureDelete = async () => {
    return await deleteProfilePicture({ personId });
  };

  const informationChipProps = getInformationChipProps({
    ...informationChipData,
    handleUpgradeMembershipClick: async () => {
      await postUpgradeMembershipApplication({ membershipYear, personId });
      router.push(membershipApplicationUrl);
    },
    membershipApplicationUrl,
  });

  return (
    <HeroSectionContentWrapper>
      <ProfileDashboardHeroSection
        companyAdminSlot={
          <HeroSectionCompanyAdmin
            cmsContent={companyAdminSectionCMSContent}
            showCompanyAdminSection={showCompanyAdminSection}
          />
        }
        profilePhotoSlot={
          <ProfilePhotoContainer
            {...profilePhotoContainerPartialProps}
            abortOngoingRequests={abortOngoingRequests}
            dialogComponent={Dialog}
            handleProfilePictureDelete={handleProfilePictureDelete}
            handleSubmitProfilePicture={handleSubmitProfilePicture}
          />
        }
        profileSummaryActionsSlot={
          <ProfileSummaryActions>
            {informationChipProps?.label && (
              <Chip {...informationChipProps} dataTestId="chip-information-button" isSmall />
            )}
          </ProfileSummaryActions>
        }
        profileSummarySlot={<ProfileSummary {...profileSummaryProps} />}
      />
    </HeroSectionContentWrapper>
  );
};
