'use client';

import classNames from 'classnames';

import { usePathname } from '../../i18n';
import { ERROR_PATH, NOT_AUTHORIZED_PATH } from '../../utils/constants';
import { type LocalPaths } from '../../utils/data/get-local-paths';
import { handleGTMHeaderClickEvent, hasCurrentRouteSubMenu } from '../../utils/helpers';
import { type LanguagesContent } from '../../utils/hooks/get-languages-content';
import { type ProfileSummary } from '../../utils/types';
import { type NavigationItem } from '../../utils/types/navigation-items';
import { ContentContainer } from '../content-container';

import { HeaderContent } from './components/header-content/header-content';
import { HeaderContentMobile } from './components/header-content-mobile/header-content-mobile';
import { LoaderStripContainer } from './components/loader-strip-container';
import { type HeaderAccountDropdownContent } from './graphql';
import styles from './header.module.scss';

type HeaderClientProps = {
  headerContentProps: {
    homeLabel: string;
    isAuthenticated: boolean;
    languagesCmsContent: LanguagesContent;
    localeURLSegment: string;
    profileDropdownCmsContent: HeaderAccountDropdownContent;
    profileSummary: ProfileSummary;
    statusChipLabel: string | undefined;
    statusChipUrl: string;
  };
  navigationItems: NavigationItem[];
  paths: LocalPaths;
  showHeader: boolean;
};

export const HeaderClient = ({
  headerContentProps,
  navigationItems,
  paths,
  showHeader,
}: HeaderClientProps) => {
  const pathname = usePathname();

  const hasSubMenu = hasCurrentRouteSubMenu(pathname, navigationItems);

  const primaryHeaderThemePaths = [
    paths.learnPagePath,
    paths.accountSettingsPagePath,
    paths.searchPagePath,
    paths.adminDashboardPagePath,
    paths.quizPagePath,
    ERROR_PATH,
    NOT_AUTHORIZED_PATH,
  ];

  const isPrimaryTheme = primaryHeaderThemePaths.some((path) => path && pathname.startsWith(path));

  return (
    <>
      {showHeader ? null : (
        <div
          className={classNames(styles['header-wrapper'], {
            [styles['header-wrapper--secondary']]: !isPrimaryTheme,
            [styles['header-wrapper--has-sub-menu']]: hasSubMenu,
          })}
        >
          <ContentContainer isWide>
            <header
              className={styles.header}
              id="header"
              onAuxClick={handleGTMHeaderClickEvent}
              onClick={handleGTMHeaderClickEvent}
            >
              <HeaderContentMobile
                isSecondaryTheme={!isPrimaryTheme}
                navigationItems={navigationItems}
                {...headerContentProps}
              />
              <HeaderContent
                isSecondaryTheme={!isPrimaryTheme}
                navigationItems={navigationItems}
                {...headerContentProps}
              />
            </header>
          </ContentContainer>
          <div className={styles['loader-container']}>
            <LoaderStripContainer />
          </div>
        </div>
      )}
    </>
  );
};
