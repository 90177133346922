import { MdrtLogo } from '@shared/ui-components';

import styles from './loader-spinner.module.scss';

export const LoaderSpinner = () => {
  return (
    <>
      <MdrtLogo className={styles['loader-logo']} />
      <div className={styles['loader-spinner']}>
        <svg viewBox="22 22 44 44">
          <circle cx="44" cy="44" fill="none" r="20" strokeWidth="2" />
        </svg>
      </div>
    </>
  );
};
