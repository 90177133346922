export const SEARCH_QUERY_PARAM_KEY = 'q';
export const SEARCH_PAGE_PARAM_KEY = 'p';
export const SEARCH_CONTENT_TYPE_PARAM_KEY = 'type';
export const SEARCH_YEAR_PARAM_KEY = 'year';
export const SEARCH_SOURCE_PARAM_KEY = 'source';
export const SEARCH_TOPIC_PARAM_KEY = 'topic';
export const SEARCH_PERSON_PARAM_KEY = 'person';
export const SEARCH_SAVED_CONTENT_PARAM_KEY = 'savedContent';

export const SEARCH_LIMIT_PER_PAGE = 10;

export const VALID_SEARCH_PARAMS = [
  SEARCH_QUERY_PARAM_KEY,
  SEARCH_PAGE_PARAM_KEY,
  SEARCH_CONTENT_TYPE_PARAM_KEY,
  SEARCH_YEAR_PARAM_KEY,
  SEARCH_SOURCE_PARAM_KEY,
  SEARCH_TOPIC_PARAM_KEY,
  SEARCH_PERSON_PARAM_KEY,
  SEARCH_SAVED_CONTENT_PARAM_KEY,
];
