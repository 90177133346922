'use client';

import classNames from 'classnames';
import { type PropsWithChildren, type ReactElement, useRef } from 'react';
import { useDialog } from 'react-aria';
import { type DialogProps } from 'react-aria-components';

import { Button } from '../button';
import { CloseIcon } from '../svgs';

import styles from './dialog.module.scss';

export type CustomDialogProps = PropsWithChildren<
  {
    className?: string;
    closeButtonLabel?: string;
    footer?: ReactElement;
    footerClassName?: string;
    hasCloseButton?: boolean;
    headerClassName?: string;
    isLoading?: boolean;
    loader: ReactElement;
    onModalClose?: () => void;
    subtitle?: string;
    title?: string;
    titleId?: string;
  } & DialogProps
>;

export const Dialog = ({
  children,
  className,
  closeButtonLabel,
  footer,
  footerClassName,
  hasCloseButton = true,
  headerClassName,
  isLoading,
  loader,
  onModalClose,
  subtitle,
  title,
  titleId,
  ...props
}: CustomDialogProps) => {
  const ref = useRef(null);
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      {...dialogProps}
      className={classNames(styles.dialog, className, 'flyout')}
      data-testid="dialog-container"
      ref={ref}
    >
      {title && (
        <div
          className={classNames(styles['dialog__header'], headerClassName)}
          data-testid="dialog-header"
        >
          <h2
            {...titleProps}
            className={styles['dialog__title']}
            data-testid="dialog-header-title"
            id={titleId}
          >
            {title}
          </h2>
          {hasCloseButton && (
            <Button
              Icon={CloseIcon}
              aria-label={closeButtonLabel || 'close'}
              data-ga-exclude-tracking
              dataTestId="dialog-header-close-button"
              onClick={onModalClose}
            />
          )}
          {subtitle && <p className={styles['dialog__subtitle']}>{subtitle}</p>}
        </div>
      )}

      <div className={styles['dialog__loading-area']}>
        {isLoading && loader}
        <div className={styles['dialog__content']} data-testid="dialog-content">
          {children}
        </div>

        {footer && (
          <div
            className={classNames(styles['dialog__footer'], footerClassName)}
            data-testid="dialog-footer"
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};
